<template>
  <div class="about">
    <div class="nav">
      <div class="container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">平台简介</el-menu-item>
          <el-menu-item index="2">平台资质</el-menu-item>
          <el-menu-item index="3">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="content" v-if="activeIndex !== '2'">
      <div
        class="content-text"
        v-html="listData.length > 0 ? listData[0].content : ''"
      ></div>
    </div>
    <div class="container" v-if="activeIndex === '2'">
      <ul class="img-list" v-loading="loading">
        <li v-for="item of listData" :key="item.id">
          <div class="img-box">
            <el-image 
              @click="clickNum(item.id)"
              style="height: 228px; width: auto;"
              :src="baseURL+item.videoCover" 
              :preview-src-list="[baseURL+item.videoCover]">
            </el-image>
          </div>
          <div class="title">
            <h3>
              {{item.title}}
            </h3>
          </div>
        </li>
      </ul>
      <el-pagination
      v-if="total > 12"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="currentChange"
      :page-sizes="[5, 10, 20]"
      layout="prev, pager, next"
      background
      prev-text="上一页"
      next-text="下一页"
    ></el-pagination>
    </div>
    <div class="no-data" v-if="total === 0 && listData.length === 0">
      <img src="@/assets/images/new-ui/gy_pic_ss_none@2x.png" alt="">
      <div>对不起，搜索无结果</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import indexApi from "@/api/index.js";
export default {
  created() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type
    }
    this.renderList()
  },
  data() {
    return {
      loading: false,
      activeIndex: '1',
      serchValue: '',
      currentPage: 1,
      pageSize: 12,
      total: 0,
      listData: [],
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      this.activeIndex = newVal.type ?? this.activeIndex
      this.renderList()
    }
  },
  methods: {
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: this.currentPage,
          size: this.pageSize,
          module: '5', //(1.通知公告 2.最新动态 3政策法规 4下载中心 5关于我们 6 帮助中心)
          serviceType: this.activeIndex,
          sort: "asc",
          sortFiled: "sort",
          title: this.serchValue,
        });
        if (code === 200) {
          this.listData = [...data.records]
          this.total = data.total;
          if ((this.activeIndex === '3' || this.activeIndex === '1') && this.listData[0]) {
            await indexApi.imageClick({id: this.listData[0].id})
          }
        }
      } finally {
        this.loading = false
      }
    },
    delHtmlTag (val) {
      if (val) {
        return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
      }
      return ''
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    handleSelect(key) {
      this.currentPage = 1;
      this.activeIndex = key
      // this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          type: key,
        }
      })
    },
    serch(val) {
      this.currentPage = 1;
      this.serchValue = val
      this.renderList()
    },
    // 下载相关函数
    handleDownload(v) {
      let path = v.attachFilePath
      try {
        path = JSON.parse(path)[0]
      } catch (error) {}
      window.open(this.baseURL + path);
    }
  }
};
</script>

<style lang="less" scoped>
.about {
  margin-bottom: 32px;
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #C3C3C3;
    margin-bottom: 32px;
  }
  .nav {
    background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
    box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
    height: 60px;
    margin-bottom: 20px;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .el-menu-demo {
          border-bottom: none;
          background: none;
          .el-menu-item {
            padding: 0;
            font-size: 18px;
            margin-right: 56px;
            color: #999;
            border-bottom: none !important;
            transition: background-color .3s,color .3s;
            &:hover {
              color: #F36E31;
              background: none;
              border-bottom: none !important;
              font-weight: bold;
            }
          }
          .is-active {
            color: #333;
            border-bottom: none;
            position: relative;
            color: #F36E31;
            font-weight: bold;
            background: none;
            
          }
        }
    }
  }
  .content {
    width: 900px;
    margin: 0 auto;
  }
  .img-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    li:nth-of-type(4n){margin-right: 0;}
    li {
      margin-right: 20px;
      width: calc((100% - 60px) / 4); // 这个是公式，减去边距以后一行放多少个。
      margin-bottom: 20px;
      &:hover {
        .img-box {
          box-shadow: 0px 8px 16px 1px rgba(0, 0, 0, 0.05);
        }
        .title {
          &::after {
            content: '';
            position: absolute;
            width: 285px;
            height: 1px;
            background-color: #F36E31;
            bottom: -1px;
            left: 0;
          }
        }
      }
      .img-box {
        width: 100%;
        height: 228px;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
        text-align: center;
        transition: all 0.4s;

        >img {
          height: 228px;
        }
        .el-image {
          display: flex;
          justify-content: center;
        }
        /deep/ .el-image__inner {
          width: auto;
        }
      }
      .title {
        text-align: center;
        line-height: 40px;
        border-bottom: 1px solid #DEDEDE;
        transition: all 0.4s;
        position: relative;
        h3 {
          color: #20201E;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          background-color: #F36E31;
          bottom: -1px;
          left: 0;
          transition: width 0.4s ease-in-out;
        }
      }
    }
  }
  .content-text {
    ::v-deep h1 {
      font-weight: bold !important;
    }
    ::v-deep h2 {
      font-weight: bold !important;
    }
    ::v-deep h3 {
      font-weight: bold !important;
    }
    ::v-deep h4 {
      font-weight: bold !important;
    }
    ::v-deep h5 {
      font-weight: bold !important;
    }
    ::v-deep h6 {
      font-weight: bold !important;
    }
  }
}
</style>